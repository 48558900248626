import _defineProperty from "/var/www/vhosts/voicebrief.app/httpdocs/next/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useMemo } from 'react';
import LocalStorage from 'services/LocalStorage';
import create from 'zustand';
var store;
var initialState = {
  lastUpdate: 0,
  light: false,
  count: 0,
  me: LocalStorage.getItem('me')
};

function initStore() {
  var preloadedState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  return create(function (set, get) {
    return _objectSpread(_objectSpread(_objectSpread({}, initialState), preloadedState), {}, {
      tick: function tick(lastUpdate, light) {
        set({
          lastUpdate: lastUpdate,
          light: !!light
        });
      },
      increment: function increment() {
        set({
          //@ts-ignore
          count: get().count + 1
        });
      },
      decrement: function decrement() {
        set({
          //@ts-ignore
          count: get().count - 1
        });
      },
      reset: function reset() {
        set({
          count: initialState.count
        });
      }
    });
  });
}

export var initializeStore = function initializeStore(preloadedState) {
  var _store2;

  var _store = (_store2 = store) !== null && _store2 !== void 0 ? _store2 : initStore(preloadedState); // After navigating to a page with an initial Zustand state, merge that state
  // with the current state in the store, and create a new store


  if (preloadedState && store) {
    _store = initStore(_objectSpread(_objectSpread({}, store.getState()), preloadedState)); // Reset the current store

    store = undefined;
  } // For SSG and SSR always create a new store


  if (false) return _store; // Create the store once in the client

  if (!store) store = _store;
  return _store;
};
export function useHydrate(initialState) {
  var state = typeof initialState === 'string' ? JSON.parse(initialState) : initialState;
  var store = useMemo(function () {
    return initializeStore(state);
  }, [state]);
  return store;
}