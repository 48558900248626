export default {
  defaultTitle: 'Brief',
  titleTemplate: 'Brief | %s',
  openGraph: {
    type: 'website',
    locale: 'it_IT',
    url: 'https://www.voicebrief.app/',
    site_name: 'Brief' // description: 'A different description for open graph if you want.',
    // images: [
    //   {
    //     url: 'https://loremflickr.com/800/600',
    //     width: 800,
    //     height: 600,
    //     alt: 'Og Image Alt'
    //   }
    // ]

  },
  twitter: {
    handle: '@Brief56130360',
    site: '@brief' //cardType: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. '

  }
};